// SCSS

.sticky-container {
	z-index:99;
}

#uitwerking-menu {
	background-color: rgba(255,255,255,0);
	transition: all 0.4s ease-in-out;
}

.top-bar, .top-bar ul {
	background-color:transparent;
	padding: 0;
}

.top-bar-right {
	text-transform: uppercase;
	font-weight: bold;
}

.title-bar {
	position: relative;
	z-index: 1;
	background-color: rgba(255,245,250,0);
	justify-content: space-between;
}


.menu {
	position: fixed;
	
	@include breakpoint(xlarge) {
		position: relative;
	}	
	a {
		color: $light-gray;
	}
	a.is-active {
		color: $white;
		background-color: $secondary-color;
  }
} 

.menu-text, .title-bar-title {
	font-size: 2rem;
	font-family: 'Segoe UI Black', sans-serif;
	font-weight: normal !important;
	color: rgb(89,89,89) !important;
		span {
			color: #256885;		
		}
}
.menu.vertical {
	background-color: $medium-gray;
	width: 100%;
	left: 0;
	top: 0;
	
	@include breakpoint(xlarge) {
		background-color: transparent;
	}	
}
