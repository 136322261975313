// CSS GLOBAL CUSTOM

@font-face {
  font-family: 'UitwerkingIcons';
  src:  url('../fonts/uitwerkingicons.eot?lojbfs');
  src:  url('../fonts/uitwerkingicons.eot?lojbfs#iefix') format('embedded-opentype'),
    url('../fonts/uitwerkingicons.ttf?lojbfs') format('truetype'),
    url('../fonts/uitwerkingicons.woff?lojbfs') format('woff'),
    url('../fonts/uitwerkingicons.svg?lojbfs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'UitwerkingIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ondersteuning:before {
  content: "\e900";
}


// Header

section header {
	padding-bottom: 1rem;
	
	h1 {
		text-transform: uppercase;
		margin: 15px 0 0 0;
		padding: 0 0 10px 0;
		line-height: 40px;
	}
	h2 {
		text-transform: uppercase;
		padding: 0;
		margin:0;
	}
	@include breakpoint(large) {
	padding-bottom: 0;	
	}
}

// HOME

section#dagbesteding_ondersteuning {
	padding: 0;
	background-color: $light-gray;	
}

.home {
	height: 100vh;
	background-image: url(../img/dagbesteding_ondersteuning_maatschappelijke_deelname_houten.jpg);
	margin-top: -80px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 0;
	opacity: 1;
	
	@include breakpoint(large) {
	opacity: 0;	
	}
	
	
	header {
		padding: 10px 0;
		color: $light-gray;
	}
	header h1 {
		line-height:normal;
		margin: 0;
	}
	header h2 {
		text-transform: inherit;
	}
	
	.container {
		@include xy-grid;
		position:relative;
		top: 50%;
		width: 20rem;
		margin: 0 auto;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		
		@include breakpoint(medium) {
			width: 35rem;		
		}
	}
	a {
		color: $secondary-color;
		&:hover {
			color: scale-color($secondary-color, $lightness: -14%);
		}
	}
}

// ANIMATIONS (SEE APP.JS FOR SCROLLING)

@include breakpoint(large) {
@include mui-series(2s) {
	.home h1{
		@include mui-queue(.5s, 1s, fade, slide(in,down));
	}
	.home h2 {
		@include mui-queue(.5s, 0s, fade, slide(in,up));
	}
}
}

// ONDERSTEUNING

section#ondersteuning_maatschappelijke_deelname {
	background-color: $light-gray;
	padding: 0;
}

.ondersteuning {
	background-image: url(../img/dagbesteding_ondersteuning_maatschappelijke_deelname_houten_krachtfabriek.jpg);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	
	padding: 1.5rem 0;
	opacity: 1;
	
	@include breakpoint(large) {
	padding: 8rem 0;
	background-attachment: fixed;	
	}
	
	.callout {
		border-radius: 1rem;
	}

	img {
		width: 100%;
		height: auto;
	}
		
	.overlay {
		position: absolute !important;
		z-index: 2;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: .9;
		background-color: $light-gray;
		
		@include breakpoint(large) {
			opacity: 0;
		}
		
	}
	.grid-container {
		position:relative;
		z-index: 3;
	}
	header h1 {
		border-left: solid 10px $secondary-color;
		padding-left: 1rem;
		line-height: 1.7;
		color: $light-gray;
	}
	.zooming p {
		color: $light-gray;
		font-weight:bold;
	}
	hr {
		padding: .2rem;
		width: 50%;
		background-color: $secondary-color;
	}
}

// ANIMATIONS (SEE APP.JS FOR SCROLLING)

 @include breakpoint(large) {
 	@include mui-series(1s) {
	.ondersteuning .col-1{
		@include mui-queue(.5s, 0s, fade, slide(in,left,25%));
	}
	.ondersteuning .col-2 {
		@include mui-queue(.5s, 1s, fade, slide(in,right,25%));
	}
	.ondersteuning .zooming {
		 @include mui-queue(1s, 0s, zoom);
	}
}
}

// TEAM

section#team_dagbesteding_individuele_ondersteuning {
	background:$secondary-color;
	padding: 0;
}

.team {
	
	padding: 1.5rem 0;
	opacity: 1;
	
	@include breakpoint(large) {
	padding: 6.5rem 0;
	opacity: 0;	
	}

	img {
		border-radius: 150px;
	}
	header h1 {
		border-left: solid 10px $primary-color;
		padding-left: 1rem;
		line-height: 1.7;
	}
	p.meta {
		font-size: 14px;
	}
}

// ANIMATIONS (SEE APP.JS FOR SCROLLING)

@include breakpoint(large) {
@include mui-series {
	.team .zooming {
		 @include mui-queue(1s, 0s, zoom);
	}
	.team .ilonja_horvers img {
		@include mui-queue(.5s, 0s, zoom);
	}
	.team .ilonja_horvers h5 {
		@include mui-queue(.1s, 0s, fade);
	}
	.team .ilonja_horvers p.meta {
		@include mui-queue(.5s, 0s, fade, slide(in,down));
	}
	.team .karin_van_sprang img {
		@include mui-queue(.5s, 0s, zoom);
	}
	.team .karin_van_sprang h5 {
		@include mui-queue(.1s, 0s, fade);
	}
	.team .karin_van_sprang p.meta {
		@include mui-queue(.5s, 0s, fade, slide(in,down));
	}
	.team .edith_de_meij img {
		@include mui-queue(.5s, 0s, zoom);
	}
	.team .edith_de_meij h5 {
		@include mui-queue(.1s, 0s, fade);
	}
	.team .edith_de_meij p.meta {
		@include mui-queue(.5s, 0s, fade, slide(in,down));
	}
	.team .mike_van_kuik img {
		@include mui-queue(.5s, 0s, zoom);
	}
	.team .mike_van_kuik h5 {
		@include mui-queue(.1s, 0s, fade);
	}
	.team .mike_van_kuik p.meta {
		@include mui-queue(.5s, 0s, fade, slide(in,down));
	}
}
}

// LOCATIES

section#locaties_dagbesteding {
	background-color: $medium-gray;
	padding: 0;
}

.locaties {
	background-image: url(../img/dagbesteding_ondersteuning_maatschappelijke_deelname_houten_meerpaal.jpg);
	background-attachment: scroll;
	background-size: cover;
	background-position: center left;
	background-repeat: no-repeat;
	position: relative;
	
	padding: 1.5rem 0;
	
	@include breakpoint(large) {
	padding: 7.5rem 0;
	background-attachment: fixed;
	background-size: contain;
	}

	
	.overlay {
		position: absolute !important;
		z-index: 2;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: .2;
		background-color: $light-gray;
		
	}
	.grid-container {
		position:relative;
		z-index: 3;
	}
	header h1 {
		border-left: solid 10px $primary-color;
		padding-left: 1rem;
		line-height: 1.7;
	}
	.callout {
		border-radius: 1rem;
	}
	hr {
		padding: .2rem;
		width: 50%;
		background-color: $primary-color;
	}
}

// ANIMATIONS (SEE APP.JS FOR SCROLLING)

@include breakpoint(large) {
@include mui-series (1s) {
	.locaties .dkf {
		@include mui-queue(.5s, 0s, fade, slide(in,up,));
	}
	.locaties .dmp {
		@include mui-queue(.5s, 0s, fade, slide(in,up,));
	}
	.locaties hr {
		 @include mui-queue(1s, 0s, zoom);
	}
}
}

// FOOTER

section#contact_maatschappelijke_deelname {
	color: $light-gray;
	background-color: $primary-color;
}

.contact {
	
	padding: 1.5rem 0;
	
	@include breakpoint(large) {
	padding: 9rem 0;	
	}
	
	button {
		float:right;
	}
	
	hr {
		padding: .2rem;
		width: 50%;
		background-color: $secondary-color;
	}	
	
	header h1 {
		border-left: solid 10px $secondary-color;
		padding-left: 1rem;
		line-height: 1.7;
	}
	.callout {
		border-radius: 1rem;
	}
	.callout.uitwerking {
		background-image: url(../img/uitwerking_ondersteuning_bij_maatschappelijke_deelname_individueel_dagbesteding_houten_contact.jpg);
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		
		.content {
			position:relative;
			z-index:3;
		}
	}
	.overlay {
		position: absolute !important;
		z-index: 2;
		top: 0;
		bottom: 0;
		left: 0;
		opacity: .8;
		background-color: $white;
		border-radius: 15px;
		width: 100%;
	}
}

.footer {
	color: $light-gray;
	background-color: $black;
	padding: 1.5rem 0;
	
	p {
		margin-bottom: 0;
	}
}
.animate-fade-in {
  @include mui-animation(fade);
  animation-duration: 2s;
  opacity: 1;
}
.animate-fade-out {
	@include mui-animation(fade(1,0));
  	animation-duration: 2s;
  	opacity: 0;
}

// ANIMATIONS (SEE APP.JS FOR SCROLLING)

@include breakpoint(large) {
@include mui-series (1s) {

	.contact .uitw {
		 @include mui-queue(1s, 0s, zoom);
	}	
	
	.contact .dkf {
		@include mui-queue(.5s, 0s, fade, slide(in,right,25%));
	}
	.contact .dmp {
		@include mui-queue(.5s, 0s, fade, slide(in,right,25%));
	}
	.contact hr {
		 @include mui-queue(1s, 0s, zoom);
	}
}
}

